import { DOCUMENT, isPlatformServer } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';

import { Subscription } from 'rxjs';

import { environment } from 'environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';

import { QueryService, SlugService, StateService } from '@core/helpers';
import PriceRanges from '@core/utilities/select-options';
import {
  maxPriceValidator,
  minPriceValidator,
} from '@core/validators/price.validator';

import { AppState } from '@state/app.state';
import { updateMobileFilterToggle } from '@state/mobile-search-filter/mobile-search-filter.actions';
import {
  resetFilter,
  updateFilter,
} from '@state/search-filter/search-filter.actions';
import { selectSearchFilter } from '@state/search-filter/search-filter.selectors';
import { updateKeyword } from '@state/search-keyword/search-keyword.actions';

interface PriceChangeModel {
  option: string;
  value: string;
}

@Component({
  selector: 'apd-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss'],
})
export class SearchFilterComponent implements OnInit, OnDestroy {
  @Output() toggleMobileFilter = new EventEmitter<boolean>();

  private _formSubscription: Subscription;
  private _propertyTypeAllSub: Subscription;
  private _propertyTypeApartmentSub: Subscription;
  private _propertyTypeTownHouseSub: Subscription;
  private _propertyTypeNewLandEstatesSub: Subscription;
  private _propertyTypePenthouseSub: Subscription;
  private _propertyTypePrestigeHomeSub: Subscription;
  private _propertyTypeVillaSub: Subscription;
  private _propertyTypeTerraceSub: Subscription;

  @HostListener('document:click', ['$event'])
  clickout(event: Event): void {
    const targetElement = event.target as HTMLDivElement;

    if (
      !targetElement.closest('.dropdown-container') &&
      this.specificTypeFilterExists()
    ) {
      this.showFilters = false;
    }
  }

  searchFilter$ = this.store.pipe(select(selectSearchFilter));

  cdnBasePath = environment.cdnBasePath;
  showFilters = false;
  priceRanges: Array<PriceChangeModel> = PriceRanges;
  specificFilter = null;

  filterForm = this.formBuilder.group({
    propertyType: this.formBuilder.group({
      all: [true],
      apartments: [false],
      townhouses: [false],
      newLandEstates: [false],
      penthouses: [false],
      prestigeHomes: [false],
      villas: [false],
      terraces: [false],
    }),
    bedrooms: this.formBuilder.group({
      oneBed: [false],
      twoBeds: [false],
      threeBeds: [false],
      fourBeds: [false],
      fivePlusBeds: [false],
    }),
    bathrooms: this.formBuilder.group({
      oneBath: [false],
      twoBath: [false],
      threeBath: [false],
      fourBath: [false],
      fivePlusBath: [false],
    }),
    carSpaces: this.formBuilder.group({
      oneSpace: [false],
      twoSpace: [false],
      threeSpace: [false],
      fourSpace: [false],
      fivePlusSpace: [false],
    }),
    price: this.formBuilder.group({
      min: ['Any', minPriceValidator],
      max: ['Any', maxPriceValidator],
    }),
  });

  isMobile = false;
  isServer = false;
  shortenState = '';
  suburbSlug = '';

  /**
   * Constructor
   * @param formBuilder FormBuilder
   */
  constructor(
    @Inject(PLATFORM_ID) platformId: string,
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private queryService: QueryService,
    private stateService: StateService,
    private slugService: SlugService,
    private formBuilder: FormBuilder,
    private store: Store<AppState>,
    private deviceDetectorService: DeviceDetectorService,
    private elementRef: ElementRef
  ) {
    this.isServer = isPlatformServer(platformId);
  }

  ngOnInit(): void {
    this.isMobile = this.deviceDetectorService.isMobile();

    this.searchFilter$.subscribe(store => {
      this.filterForm.patchValue(store, {
        emitEvent: false,
        onlySelf: true,
      });
    });
    this.setFormSubscriptions();
    this.getRouteParams();
  }

  ngOnDestroy(): void {
    if (!this.isServer) {
      this._formSubscription.unsubscribe();
      this._propertyTypeAllSub.unsubscribe();
      this._propertyTypeApartmentSub.unsubscribe();
      this._propertyTypeTownHouseSub.unsubscribe();
      this._propertyTypeNewLandEstatesSub.unsubscribe();
      this._propertyTypePenthouseSub.unsubscribe();
      this._propertyTypePrestigeHomeSub.unsubscribe();
      this._propertyTypeVillaSub.unsubscribe();
      this._propertyTypeTerraceSub.unsubscribe();
    }
  }

  togglePopup(event: Event = null): void {
    event == null
      ? this.handleDefaultFilter()
      : this.handleSpecificFilter(event);
  }

  handleSpecificFilter(event: Event): void {
    this.showFilters = !this.showFilters;
    const element = event.target as HTMLDivElement;

    const type = this.getFilterType(element.id);
    this.specificFilter = type !== null ? type : null;

    if (this.specificFilter !== null) {
      this.showFilters = true;
      this.toggleMobileFilter.emit(this.showFilters);

      const container = element.parentNode;

      const filterContainerElement = container.children[1];
      filterContainerElement.setAttribute('style', 'position:fixed');

      const modalOverlay = this.document.getElementById('search-modal-overlay');

      modalOverlay.classList.add('search-filters-specific');

      filterContainerElement.appendChild(modalOverlay);
    }
  }

  handleDefaultFilter(): void {
    this.showFilters = !this.showFilters;
    const currentPage = this.router.url;
    this.specificFilter = null;

    const elems = document.querySelectorAll('.search-filters-specific');

    [].forEach.call(elems, function (el) {
      el.classList.remove('search-filters-specific');
      el.parentElement.style.position = 'unset';
    });

    if (this.isMobile && currentPage == '/') {
      const homeHeroEl = this.document.getElementsByClassName('home-hero')[0],
        overlayEl = homeHeroEl.getElementsByClassName('overlay')[0];

      if (this.showFilters) {
        overlayEl.classList.add('z-idx-highest');
      } else {
        overlayEl.classList.remove('z-idx-highest');
      }
    }
    this.toggleMobileFilter.emit(this.showFilters);
  }

  getRouteParams(): void {
    this.activatedRoute.paramMap.subscribe((paramMap: ParamMap) => {
      (this.shortenState = paramMap.get('shortenState')),
        (this.suburbSlug = paramMap.get('suburbSlug'));

      if (!this.hasValue(this.shortenState))
        this.shortenState = paramMap.get('state');
      if (!this.hasValue(this.suburbSlug))
        this.suburbSlug = paramMap.get('suburb');
    });
  }

  hasValue(data: string): boolean {
    return typeof data != 'undefined' && data != null;
  }

  search(): void {
    this.store.dispatch(updateFilter({ searchFilter: this.filterForm.value }));
    this.store.dispatch(updateKeyword({ searchKeyword: '' }));

    if (this.isMobile) {
      this.store.dispatch(
        updateMobileFilterToggle({ toggleFromMapFilter: true })
      );
    }

    this.togglePopup();

    const currentPage = this.router.url;

    if (
      currentPage.includes('new-apartments-developments') &&
      this.hasValue(this.shortenState)
    ) {
      const suburbPath =
        typeof this.suburbSlug != 'undefined' && this.suburbSlug != null
          ? `/${this.suburbSlug}`
          : '';

      this.router.navigate([
        `/new-apartments-developments/${this.shortenState}${suburbPath}`,
      ]);
    }
  }

  onReset(): void {
    this.store.dispatch(resetFilter());
    this.searchFilter$.subscribe(store => {
      this.filterForm.patchValue(store, {
        emitEvent: false,
        onlySelf: true,
      });
    });
  }

  closePopup(event: Event): void {
    const element = event.target as HTMLDivElement;
    if (element.id === 'search-modal-overlay') {
      this.showFilters = false;
    }
  }

  setFormSubscriptions(): void {
    this._formSubscription = this.filterForm.valueChanges.subscribe(changes => {
      this.filterForm.controls.price
        .get('min')
        .setValidators(minPriceValidator(changes.price.max));
      this.filterForm.controls.price
        .get('max')
        .setValidators(maxPriceValidator(changes.price.min));
    });
    this._propertyTypeAllSub = this.filterForm.controls.propertyType
      .get('all')
      .valueChanges.subscribe(value => {
        if (value) {
          if (
            this.filterForm.controls.propertyType.get('townhouses').value ||
            this.filterForm.controls.propertyType.get('newLandEstates').value ||
            this.filterForm.controls.propertyType.get('apartments').value ||
            this.filterForm.controls.propertyType.get('penthouses').value ||
            this.filterForm.controls.propertyType.get('prestigeHomes').value ||
            this.filterForm.controls.propertyType.get('villas').value ||
            this.filterForm.controls.propertyType.get('terraces').value
          ) {
            this.filterForm.controls.propertyType
              .get('apartments')
              .setValue(false);
            this.filterForm.controls.propertyType
              .get('townhouses')
              .setValue(false);
            this.filterForm.controls.propertyType
              .get('newLandEstates')
              .setValue(false);
            this.filterForm.controls.propertyType
              .get('penthouses')
              .setValue(false);
            this.filterForm.controls.propertyType
              .get('prestigeHomes')
              .setValue(false);
            this.filterForm.controls.propertyType.get('villas').setValue(false);
            this.filterForm.controls.propertyType
              .get('terraces')
              .setValue(false);
          }
        }
      });
    this._propertyTypeApartmentSub = this.filterForm.controls.propertyType
      .get('apartments')
      .valueChanges.subscribe(value => {
        if (value) {
          this.filterForm.controls.propertyType.get('all').setValue(false);
        }
        if (
          !this.filterForm.controls.propertyType.get('townhouses').value &&
          !this.filterForm.controls.propertyType.get('newLandEstates').value &&
          !this.filterForm.controls.propertyType.get('penthouses').value &&
          !this.filterForm.controls.propertyType.get('prestigeHomes').value &&
          !this.filterForm.controls.propertyType.get('villas').value &&
          !this.filterForm.controls.propertyType.get('terraces').value &&
          !value
        ) {
          this.filterForm.controls.propertyType.get('all').setValue(true);
        }
      });
    this._propertyTypeTownHouseSub = this.filterForm.controls.propertyType
      .get('townhouses')
      .valueChanges.subscribe(value => {
        if (value) {
          this.filterForm.controls.propertyType.get('all').setValue(false);
        }
        if (
          !this.filterForm.controls.propertyType.get('apartments').value &&
          !this.filterForm.controls.propertyType.get('newLandEstates').value &&
          !this.filterForm.controls.propertyType.get('penthouses').value &&
          !this.filterForm.controls.propertyType.get('prestigeHomes').value &&
          !this.filterForm.controls.propertyType.get('villas').value &&
          !this.filterForm.controls.propertyType.get('terraces').value &&
          !value
        ) {
          this.filterForm.controls.propertyType.get('all').setValue(true);
        }
      });

    this._propertyTypeNewLandEstatesSub = this.filterForm.controls.propertyType
      .get('newLandEstates')
      .valueChanges.subscribe(value => {
        if (value) {
          this.filterForm.controls.propertyType.get('all').setValue(false);
        }
        if (
          !this.filterForm.controls.propertyType.get('apartments').value &&
          !this.filterForm.controls.propertyType.get('townhouses').value &&
          !this.filterForm.controls.propertyType.get('penthouses').value &&
          !this.filterForm.controls.propertyType.get('prestigeHomes').value &&
          !this.filterForm.controls.propertyType.get('villas').value &&
          !this.filterForm.controls.propertyType.get('terraces').value &&
          !value
        ) {
          this.filterForm.controls.propertyType.get('all').setValue(true);
        }
      });

    this._propertyTypePenthouseSub = this.filterForm.controls.propertyType
      .get('penthouses')
      .valueChanges.subscribe(value => {
        if (value) {
          this.filterForm.controls.propertyType.get('all').setValue(false);
        }
        if (
          !this.filterForm.controls.propertyType.get('apartments').value &&
          !this.filterForm.controls.propertyType.get('townhouses').value &&
          !this.filterForm.controls.propertyType.get('newLandEstates').value &&
          !this.filterForm.controls.propertyType.get('prestigeHomes').value &&
          !this.filterForm.controls.propertyType.get('villas').value &&
          !this.filterForm.controls.propertyType.get('terraces').value &&
          !value
        ) {
          this.filterForm.controls.propertyType.get('all').setValue(true);
        }
      });

    this._propertyTypePrestigeHomeSub = this.filterForm.controls.propertyType
      .get('prestigeHomes')
      .valueChanges.subscribe(value => {
        if (value) {
          this.filterForm.controls.propertyType.get('all').setValue(false);
        }
        if (
          !this.filterForm.controls.propertyType.get('apartments').value &&
          !this.filterForm.controls.propertyType.get('townhouses').value &&
          !this.filterForm.controls.propertyType.get('newLandEstates').value &&
          !this.filterForm.controls.propertyType.get('penthouses').value &&
          !this.filterForm.controls.propertyType.get('villas').value &&
          !this.filterForm.controls.propertyType.get('terraces').value &&
          !value
        ) {
          this.filterForm.controls.propertyType.get('all').setValue(true);
        }
      });

    this._propertyTypeVillaSub = this.filterForm.controls.propertyType
      .get('villas')
      .valueChanges.subscribe(value => {
        if (value) {
          this.filterForm.controls.propertyType.get('all').setValue(false);
        }
        if (
          !this.filterForm.controls.propertyType.get('apartments').value &&
          !this.filterForm.controls.propertyType.get('townhouses').value &&
          !this.filterForm.controls.propertyType.get('newLandEstates').value &&
          !this.filterForm.controls.propertyType.get('penthouses').value &&
          !this.filterForm.controls.propertyType.get('prestigeHomes').value &&
          !this.filterForm.controls.propertyType.get('terraces').value &&
          !value
        ) {
          this.filterForm.controls.propertyType.get('all').setValue(true);
        }
      });
    this._propertyTypeTerraceSub = this.filterForm.controls.propertyType
      .get('terraces')
      .valueChanges.subscribe(value => {
        if (value) {
          this.filterForm.controls.propertyType.get('all').setValue(false);
        }
        if (
          !this.filterForm.controls.propertyType.get('apartments').value &&
          !this.filterForm.controls.propertyType.get('townhouses').value &&
          !this.filterForm.controls.propertyType.get('newLandEstates').value &&
          !this.filterForm.controls.propertyType.get('penthouses').value &&
          !this.filterForm.controls.propertyType.get('prestigeHomes').value &&
          !this.filterForm.controls.propertyType.get('villas').value &&
          !value
        ) {
          this.filterForm.controls.propertyType.get('all').setValue(true);
        }
      });
  }

  getFilterType(name: string): string {
    let result = null;
    const filterTypes = [
      'property',
      'bedrooms',
      'bathrooms',
      'carspaces',
      'price',
    ];

    for (const value of filterTypes) {
      if (name.search(value) > -1) {
        result = value;
        break;
      }
    }

    return result;
  }

  showSpecificFilter(item: string = null): boolean {
    if (this.specificFilter == null) {
      return true;
    }

    return this.specificFilter === item;
  }

  specificTypeFilterExists(): boolean {
    return this.specificFilter !== null;
  }
}
