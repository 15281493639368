import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';

interface TabInterface {
  default: string;
  translated: string;
}

@Component({
  selector: 'apd-tab-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TabDefaultComponent {
  // custom props
  @Input() labels: Array<TabInterface>;
  @Input() activeLabel = 0;

  // material props
  @Output() tabChangeEventHandler = new EventEmitter<MatTabChangeEvent>();

  onTabChange($event: MatTabChangeEvent): void {
    this.tabChangeEventHandler.emit($event);
  }
}
