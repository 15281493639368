import { StateDetail } from '@core/types';
import { PropertyTypeConfig } from '@core/types/common/filter-config.model';
import { Menu } from '@core/types/common/menu.model';
import { PriceRange } from '@core/types/common/price-range.model';
import { MapViewport } from '@core/types/map/map-viewport.model';
import { SuburbDefault } from '@core/types/suburbs/suburb.model';

export abstract class Constants {
  // Allowed languages other than 'en'
  static readonly allowedLanguages: Array<string> = ['cn', 'tr'];

  // States
  static readonly states: Array<StateDetail> = [
    {
      name: 'Victoria',
      slug: 'victoria',
      shortValue: 'vic',
      capital: {
        suburb: 'Melbourne',
        postcode: '3000',
      },
    },
    {
      name: 'Queensland',
      slug: 'queensland',
      shortValue: 'qld',
      capital: {
        suburb: 'Brisbane',
        postcode: '4000',
      },
    },
    {
      name: 'New South Wales',
      slug: 'new-south-wales',
      shortValue: 'nsw',
      capital: {
        suburb: 'Sydney',
        postcode: '2000',
      },
    },
    {
      name: 'Western Australia',
      slug: 'western-australia',
      shortValue: 'wa',
      capital: {
        suburb: 'Perth',
        postcode: '6000',
      },
    },
    {
      name: 'South Australia',
      slug: 'south-australia',
      shortValue: 'sa',
      capital: {
        suburb: 'Adelaide',
        postcode: '5000',
      },
    },
    {
      name: 'Tasmania',
      slug: 'tasmania',
      shortValue: 'tas',
      capital: {
        suburb: 'Hobart',
        postcode: '7001',
      },
    },
    {
      name: 'Australian Capital Territory',
      slug: 'australian-capital-territory',
      shortValue: 'act',
      capital: {
        suburb: 'Canberra',
        postcode: '2600',
      },
    },
    {
      name: 'Northern Territory',
      slug: 'northern-territory',
      shortValue: 'nt',
      capital: {
        suburb: 'Darwin',
        postcode: '0801',
      },
    },
  ];

  // States
  static readonly allowedLeadTypes: Array<string> = [
    'Lot Enquiry',
    'Apartment Enquiry',
    'Townhouse Enquiry',
    'House & Land Enquiry',
    'General Enquiry',
    'Archived Enquiry',
    'Brochure Enquiry',
    'Villa Enquiry',
    'Terrace Enquiry',
    'Prestige Home Enquiry',
    'Penthouse Enquiry',
  ];

  static readonly defaultSuburbs: Array<SuburbDefault> = [
    {
      description: 'Melbourne VIC, Australia, 3000',
      type: 'place',
      suburbName: 'Melbourne',
      shortenState: 'vic',
    },
    {
      description: 'Sydney NSW, Australia, 2000',
      type: 'place',
      suburbName: 'Sydney',
      shortenState: 'nsw',
    },
    {
      description: 'Newcastle NSW, Australia, 2300',
      type: 'place',
      suburbName: 'Newcastle',
      shortenState: 'nsw',
    },
    {
      description: 'Canberra ACT, Australia, 2600',
      type: 'place',
      suburbName: 'Canberra',
      shortenState: 'act',
    },
    {
      description: 'Brisbane QLD, Australia, 4000',
      type: 'place',
      suburbName: 'Brisbane',
      shortenState: 'qld',
    },
    {
      description: 'Gold Coast QLD, Australia, 4217',
      type: 'place',
      suburbName: 'Gold Coast',
      shortenState: 'qld',
    },
    {
      description: 'Sunshine Coast QLD, Australia, 4558',
      type: 'place',
      suburbName: 'Sunshine Coast',
      shortenState: 'qld',
    },
  ];

  static readonly audienceOptions: Array<string> = [
    'First Home Buyers',
    'Downsizers',
    'Young Family',
    'Investor',
    'Just Looking',
    'Owner Occupier',
  ];

  static readonly priceRangeOptions: Array<PriceRange> = [
    {
      value: '<$500k',
      limit: 500000,
    },
    {
      value: '$500k-$750k',
      limit: 750000,
    },
    {
      value: '$750k-$1m',
      limit: 1000000,
    },
    {
      value: '$1m-$1.5m',
      limit: 1500000,
    },
    {
      value: '$1.5m-$2m',
      limit: 2000000,
    },
    {
      value: '$2m-$3m',
      limit: 3000000,
    },
    {
      value: '$3m-$4m',
      limit: 4000000,
    },
    {
      value: '$4m-$5m',
      limit: 5000000,
    },
    {
      value: '$5m-$7m',
      limit: 7000000,
    },
    {
      value: '$7m-$9m',
      limit: 9000000,
    },
    {
      value: '$9m-$11m',
      limit: 11000000,
    },
    {
      value: '$11m-$17m',
      limit: 17000000,
    },
    {
      value: '$17m-$21m',
      limit: 21000000,
    },
    {
      value: '$21m+',
      limit: 0,
    },
  ];

  static readonly buyingDurationOptions: Array<string> = [
    '1-3 months',
    '3-6 months',
    '6-12 months',
    '12-18 months',
    '18 months +',
  ];

  static readonly mainMenuOptions: Array<Menu> = [
    {
      name: 'Developments',
      data: [
        {
          name: 'Victoria',
          slug: 'victoria',
          shortValue: 'vic',
        },
        {
          name: 'New South Wales',
          slug: 'new-south-wales',
          shortValue: 'nsw',
        },
        {
          name: 'Queensland',
          slug: 'queensland',
          shortValue: 'qld',
        },
        {
          name: 'Australian Capital Territory',
          slug: 'australian-capital-territory',
          shortValue: 'act',
        },
      ],
    },
    {
      name: 'News',
      data: [
        {
          name: 'Market Insights',
          slug: 'market-insight',
          url: '/buying-living/market-insights',
        },
        {
          name: 'Lifestyle',
          slug: 'lifestyle',
          url: '/buying-living/lifestyle',
        },
        {
          name: 'Guides',
          slug: 'guides',
          url: '/buying-living/guides',
        },
      ],
    },
    {
      name: 'Industry Profiles',
      data: [
        {
          name: 'Architects',
          slug: 'architects',
          url: '/industry-profiles/architects',
        },
        {
          name: 'Developers',
          slug: 'developers',
          url: '/industry-profiles/developers',
        },
        {
          name: 'Builders',
          slug: 'builders',
          url: '/industry-profiles/builders',
        },
        {
          name: 'Project Marketers',
          slug: 'project-marketers',
          url: '/industry-profiles/project-marketers',
        },
        {
          name: 'Agents',
          slug: 'agents',
          url: '/industry-profiles/agents',
        },
        {
          name: 'Interior Designers',
          slug: 'interior-designers',
          url: '/industry-profiles/interior-designers',
        },
        {
          name: 'Landscape Architects',
          slug: 'landscape-architect',
          url: '/industry-profiles/landscape-architect',
        },
        {
          name: 'Builder/Developer',
          slug: 'builder-developer',
          url: '/industry-profiles/builder-developer',
        },
        {
          name: 'Development Partner',
          slug: 'development-partner',
          url: '/industry-profiles/development-partner',
        },
      ],
    },
  ];

  // Preferred Contact Methods
  static readonly preferredContactMethods: Array<string> = [
    'Phone Call',
    'Email',
    'SMS',
  ];
  static readonly preApprovalOption: Array<string> = ['Yes', 'No'];

  static readonly propertyTypes: PropertyTypeConfig = {
    all: 'All',
    apartments: 'Apartments',
    townhouses: 'Townhouses',
    newLandEstates: 'Land Estates',
    penthouses: 'Penthouses',
    prestigeHomes: 'Prestige Homes',
    villas: 'Villas',
    terraces: 'Terraces',
  };

  static readonly filterConfigs: Array<string> = ['1', '2', '3', '4', '5+'];

  static readonly viewports: MapViewport = {
    southWest: {
      lat: -43.6345972634,
      lng: 113.338953078,
    },
    northEast: {
      lat: -10.6681857235,
      lng: 153.569469029,
    },
  };
}
