<footer>
  <div class="bg--three px-15">
    <div class="container lg top d-grid col--md-1" *ngIf="mode != 'simple'">
      <div class="col left">
        <div class="sign-up">
          <p class="title">
            {{ 'FOOTER.SIGN_UP.TITLE' | translate }}
          </p>
          <h4 class="h5 subtitle">
            {{ 'FOOTER.SIGN_UP.SUBTITLE' | translate }}
          </h4>

          <apd-subscribe
            [formOnly]="true"
            formLocation="footer"></apd-subscribe>
        </div>

        <ul class="menu list-inline d-grid col--2 col--sm-1 align-items-start">
          <li>
            <a
              [routerLink]="
                currentLang +
                '/buying-living/off-the-plan-apartment-developments-articles'
              ">
              {{ '_BUYING_LIVING' | translate }}
            </a>
          </li>
          <li *ngIf="false">
            <a href="#">
              {{ '_HOUSE_LAND_PACKAGES' | translate }}
            </a>
          </li>
          <li>
            <a [routerLink]="currentLang + '/industry-profiles'">
              {{ '_INDUSTRY_PROFILES' | translate }}
            </a>
          </li>
          <!-- <li>
                        <a
                            [routerLink]="
                                currentLang + '/off-the-plan-buyer-incentives'
                            "
                        >
                            {{ '_BUYER_INCENTIVES' | translate }}
                        </a>
                    </li> -->
        </ul>

        <div class="social">
          <a
            href="https://www.facebook.com/apartmentdevelopments/"
            rel="noopener"
            target="_blank">
            <img
              loading="lazy"
              [src]="cdnBasePath + '/assets/icon/facebook.svg' | optimizeImage"
              alt="Facebook"
              id="facebook" />
          </a>
          <a
            href="https://www.instagram.com/apartmentsanddevelopments/"
            rel="noopener"
            target="_blank">
            <img
              loading="lazy"
              [src]="cdnBasePath + '/assets/icon/instagram.svg' | optimizeImage"
              alt="Instagram"
              id="instagram" />
          </a>
          <a
            href="https://www.linkedin.com/company/adgroup/"
            rel="noopener"
            target="_blank">
            <img
              loading="lazy"
              [src]="cdnBasePath + '/assets/icon/linkedin.svg' | optimizeImage"
              alt="Linkedin" />
          </a>
          <a
            href="https://www.youtube.com/channel/UC0aX7jZzYvCIFVa0yXGj0Ag"
            rel="noopener"
            target="_blank">
            <img
              loading="lazy"
              [src]="cdnBasePath + '/assets/icon/youtube.svg' | optimizeImage"
              alt="Youtube"
              id="youtube" />
          </a>
        </div>

        <div class="affiliates">
          <a
            href="https://www.developmentid.com.au/"
            target="_blank"
            rel="noopener">
            <img
              loading="lazy"
              [src]="
                cdnBasePath + '/assets/brand/logo-development_id.svg'
                  | optimizeImage
              "
              alt="Development ID" />
          </a>
          <a href="http://ad-group.com.au/" target="_blank" rel="noopener">
            <img
              loading="lazy"
              [src]="
                cdnBasePath + '/assets/brand/logo-ad_group.svg' | optimizeImage
              "
              alt="AD Group" />
          </a>
        </div>
      </div>

      <div class="col right">
        <ul class="select-state list-inline d-grid">
          <!-- <li
                        *ngIf="fromSearchPage && searchedSuburb"
                        [ngClass]="showSeoTextDiv ? 'active' : ''"
                    >
                        <a (click)="showSeoText(true)"
                            >{{ searchedSuburb ? searchedSuburb.title : ''
                            }}<img
                                loading="lazy"
                                [src]="
                                    cdnBasePath +
                                        '/assets/icon/down-chevron-blue.svg'
                                        | optimizeImage
                                "
                                alt="Icon"
                                class="icon"
                        /></a>
                    </li> -->
          <li
            [ngClass]="{
              active: state == 'nsw' && !showSeoTextDiv
            }"
            *ngIf="state | displayStateTab: 'nsw':fromSearchPage">
            <a (click)="setState('nsw')"
              >New South Wales
              <img
                loading="lazy"
                [src]="
                  cdnBasePath + '/assets/icon/down-chevron-blue.svg'
                    | optimizeImage
                "
                alt="Icon"
                class="icon"
            /></a>
          </li>
          <li
            [ngClass]="{
              active: state == 'vic' && !showSeoTextDiv
            }"
            *ngIf="state | displayStateTab: 'vic':fromSearchPage">
            <a (click)="setState('vic')"
              >Victoria
              <img
                loading="lazy"
                [src]="
                  cdnBasePath + '/assets/icon/down-chevron-blue.svg'
                    | optimizeImage
                "
                alt="Icon"
                class="icon"
            /></a>
          </li>
          <li
            [ngClass]="{
              active: state == 'qld' && !showSeoTextDiv
            }"
            *ngIf="state | displayStateTab: 'qld':fromSearchPage">
            <a (click)="setState('qld')"
              >Queensland
              <img
                loading="lazy"
                [src]="
                  cdnBasePath + '/assets/icon/down-chevron-blue.svg'
                    | optimizeImage
                "
                alt="Icon"
                class="icon"
            /></a>
          </li>
          <li
            [ngClass]="{
              active: state == 'act' && !showSeoTextDiv
            }"
            *ngIf="state | displayStateTab: 'act':fromSearchPage">
            <a (click)="setState('act')"
              >Australian Capital Territory<img
                loading="lazy"
                [src]="
                  cdnBasePath + '/assets/icon/down-chevron-blue.svg'
                    | optimizeImage
                "
                alt="Icon"
                class="icon"
            /></a>
          </li>
          <li
            [ngClass]="{ active: state == 'sa' && !showSeoTextDiv }"
            *ngIf="state | displayStateTab: 'sa':fromSearchPage">
            <a (click)="setState('sa')"
              >South Australia<img
                loading="lazy"
                [src]="
                  cdnBasePath + '/assets/icon/down-chevron-blue.svg'
                    | optimizeImage
                "
                alt="Icon"
                class="icon"
            /></a>
          </li>
        </ul>

        <h5 class="title" *ngIf="!fromSearchPage">
          <b>{{ stateService.getName(state) }}</b>
          {{ 'FOOTER.INVESTMENT_PROPERTIES' | translate }}
        </h5>

        <ul class="result-tabs list-inline mt-20" *ngIf="!fromSearchPage">
          <li
            [ngClass]="{
              active:
                bedrooms == '1' &&
                classifications == 'apartments,new-land-estates,townhouses'
            }">
            <a (click)="setBedroom('1')"
              >1 {{ '_PROPERTY.BEDROOM.SINGULAR' | translate }}</a
            >
          </li>
          <li
            [ngClass]="{
              active:
                bedrooms == '2' &&
                classifications == 'apartments,new-land-estates,townhouses'
            }">
            <a (click)="setBedroom('2')"
              >2 {{ '_PROPERTY.BEDROOM.PLURAL' | translate }}</a
            >
          </li>
          <li
            [ngClass]="{
              active:
                bedrooms == '3' &&
                classifications == 'apartments,new-land-estates,townhouses'
            }">
            <a (click)="setBedroom('3')"
              >3 {{ '_PROPERTY.BEDROOM.PLURAL' | translate }}</a
            >
          </li>
          <li
            [ngClass]="{
              active: !bedrooms && classifications == 'townhouses'
            }">
            <a (click)="setClassification('townhouses')">{{
              '_DEVELOPMENT.TOWNHOUSE.SINGULAR' | translate
            }}</a>
          </li>
          <li
            [ngClass]="{
              active: !bedrooms && classifications == 'new-land-estates'
            }">
            <a (click)="setClassification('new-land-estates')">{{
              '_DEVELOPMENT.LAND_ESTATE.PLURAL' | translate
            }}</a>
          </li>
        </ul>

        <!-- <div class="seo-text" *ngIf="showSeoTextDiv">
                     <div class="container">
                        <p>
                            Find
                            <strong
                                >new apartments in {{ searchedSuburb.title }}
                                {{ searchedSuburb.postalNumber }}
                                {{ searchedSuburb.state }}</strong
                            >
                            from over
                            <strong
                                >{{
                                    searchedSuburb.propertyCount
                                        ? searchedSuburb.propertyCount
                                        : ''
                                }}
                                {{ searchedSuburb.title }} apartments for
                                sale</strong
                            >
                            and
                            <strong
                                >{{
                                    searchedSuburb.projectCount
                                        ? searchedSuburb.projectCount
                                        : ''
                                }}
                                Off the plan apartments projects in
                                {{ searchedSuburb.title }}</strong
                            >. Find the best
                            <strong
                                >off the plan apartments in
                                {{ searchedSuburb.title }}</strong
                            >
                            and
                            <strong
                                >new apartments near
                                {{ searchedSuburb.title }}</strong
                            >. Get the latest
                            <strong
                                >{{ searchedSuburb.title }} property market
                                insights</strong
                            >
                            and find
                            <strong
                                >brand new townhouses in
                                {{ searchedSuburb.title }}</strong
                            >
                            and
                            <strong
                                >homes for sale in {{ searchedSuburb.title }}.
                                Apartments for sale in
                                {{ searchedSuburb.title }}</strong
                            >
                            <ng-container
                                *ngIf="searchedSuburb.apartmentFromPrice"
                            >
                                start from ${{
                                    searchedSuburb.apartmentFromPrice
                                }}</ng-container
                            >
                            and
                            <strong
                                >Townhouses for sale in
                                {{ searchedSuburb.title }}</strong
                            ><ng-container
                                *ngIf="searchedSuburb.townhousesFromPrice"
                            >
                                start from ${{
                                    searchedSuburb.townhousesFromPrice
                                }}</ng-container
                            >.
                        </p>
                    </div> 
                </div> -->

        <ul
          class="suburb-results list-inline d-grid"
          [class]="!fromSearchPage ? 'col--4 col--sm-2' : 'col--3 col--sm-1'"
          *ngIf="suburbs.length > 0 && !isLoading && !showSeoTextDiv">
          <li *ngFor="let suburb of this.suburbs">
            <a
              [routerLink]="
                currentLang +
                '/new-apartments-developments/' +
                state +
                '/' +
                (fromSearchPage ? suburb.slug.name : suburb.slug)
              ">
              {{ suburb.area }}{{ fromSearchPage ? ' new apartments' : '' }}
            </a>
          </li>
        </ul>

        <h5 class="title no-results" *ngIf="suburbs.length == 0 && !isLoading">
          No results found.
        </h5>

        <div class="spinner-container" *ngIf="isLoading">
          <mat-spinner [diameter]="50"> </mat-spinner>
        </div>
      </div>
    </div>
  </div>

  <div
    class="bg--one px-15"
    [ngClass]="{ 'simple-mode-background': mode === 'simple' }">
    <div
      class="container lg d-grid bottom col--md-1"
      [ngClass]="{ 'bottom-grid': mode != 'simple' }">
      <ul *ngIf="mode != 'simple'" class="menu list-inline">
        <li><a [routerLink]="currentLang + '/about'">About us</a></li>
        <li>
          <a [routerLink]="currentLang + '/advertise-with-us'"
            >Advertise with us</a
          >
        </li>
        <li>
          <a [routerLink]="currentLang + '/privacy-policy'">Privacy Policy</a>
        </li>
        <li>
          <a [routerLink]="currentLang + '/terms-and-conditions'"
            >Terms of use</a
          >
        </li>
        <li><a [routerLink]="currentLang + '/sitemap'">Sitemap</a></li>
      </ul>

      <div [ngClass]="{ 'text-right': mode != 'simple' }">
        <p>
          &copy; {{ dateNow | date: 'yyyy' }} AD Group (Australia) Holdings Pty
          Ltd. All rights reserved.
        </p>
      </div>
    </div>
  </div>
</footer>
